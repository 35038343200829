import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
  FaArrowAltCircleRight,
  FaFile,
  FaFileDownload,
  FaFilePdf,
  FaHistory,
  FaLanguage,
  FaSearch,
  FaTextHeight,
} from "react-icons/fa";
import { MaterialReactTable } from "material-react-table";

import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { MRT_Localization_ES } from "material-react-table/locales/es";

import {
  AccountCircle,
  Download,
  Search,
  Send,
  Update,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import { ClipPath, View } from "@react-pdf/renderer";
import Historial from "./HistorialCertificados";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
function ConsultarAutenticado() {
  const cookie = new Cookies();
  let params = useParams();
  const [lista, setLista] = useState([]);
  useEffect(() => {
    axios({
      auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
      method: "get",
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      url:
        "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerUsuario/" +
        params.correo,
    })
      .then((Response) => {
        setLista(Response.nombre);
        var valida = Response.data;
        var respuesta = Response.data[0];
        console.log(Response.data);
        if (valida.length > 0) {
          cookie.set("id", respuesta.id, { path: "/" });
          cookie.set("identificacion", respuesta.identificacion, { path: "/" });
          cookie.set("correo", respuesta.correo, { path: "/" });
          cookie.set("ut", respuesta.ut, { path: "/" });
          cookie.set("tipoUsuario", respuesta.tipoUsuario, { path: "/" });

          cookie.set(("tipoUsuario", respuesta.tipoUsuario, { path: "/" }));
          cookie.set("cargo", respuesta.cargo, { path: "/" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setLista]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        filterVariant: "text",
        enableEditing: false,
      },

      {
        accessorKey: "id_certificado",
        header: "id_certificado",
        filterVariant: "text",
        enableEditing: false,
      },
      {
        accessorKey: "emitido",
        header: "Emitido",
        filterVariant: "text",
      },

      {
        accessorKey: "modulo",
        header: "Modulo",
        filterVariant: "text",
      },
      {
        accessorKey: "numero_certificado",
        header: "Número Certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "correo",
        header: "correo",
        filterVariant: "text",
      },
      {
        accessorKey: "envio_correo",
        header: "Envió correo",
        filterVariant: "text",
      },
      {
        accessorKey: "documento",
        header: "documento",
        filterVariant: "text",
      },
      {
        accessorKey: "nombre",
        header: "nombre",
        filterVariant: "text",
      },
      {
        accessorKey: "asistio",
        header: "asistio",
        filterVariant: "text",
      },
      {
        accessorKey: "asistio",
        header: "asistio",
        filterVariant: "text",
      },
      {
        accessorKey: "certificado",
        header: "certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "certificado_ingles",
        header: "certificado_ingles",
        filterVariant: "text",
      },
      {
        accessorKey: "alcance",
        header: "alcance",
        filterVariant: "text",
      },
      {
        accessorKey: "alcance_ingles",
        header: "alcance_ingles",
        filterVariant: "text",
      },
      {
        accessorKey: "intensidad",
        header: "intensidad",
        filterVariant: "text",
      },
      {
        accessorKey: "listado_cursos",
        header: "listado_cursos",
        filterVariant: "text",
      },
      {
        accessorKey: "ciudad",
        header: "ciudad",
        filterVariant: "text",
      },
      {
        accessorKey: "pais",
        header: "pais",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_certificado",
        header: "fecha_certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_aprobacion",
        header: "fecha_aprobacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_ultima_modificacion",
        header: "fecha_ultima_modificacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_renovacion",
        header: "fecha_renovacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_vencimiento",
        header: "fecha_vencimiento",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_impresion",
        header: "fecha_impresion",
        filterVariant: "text",
      },
      {
        accessorKey: "direccion",
        header: "direccion",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_1",
        header: "dato_1",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_2",
        header: "dato_2",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_3",
        header: "dato_3",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_4",
        header: "dato_4",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_5",
        header: "dato_5",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_6",
        header: "dato_6",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_7",
        header: "dato_7",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_8",
        header: "dato_8",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_9",
        header: "dato_9",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_10",
        header: "dato_10",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_11",
        header: "dato_11",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_12",
        header: "dato_12",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_13",
        header: "dato_13",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_14",
        header: "dato_14",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_15",
        header: "dato_15",
        filterVariant: "text",
      },

      {
        accessorKey: "dato_16",
        header: "dato_16",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_17",
        header: "dato_17",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_18",
        header: "dato_18",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_19",
        header: "dato_19",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_20",
        header: "dato_20",
        filterVariant: "text",
      },
      {
        accessorKey: "regional",
        header: "Regional",
        filterVariant: "text",
      },
    ],
    []
  );
  const [list, setList] = useState([]);
  console.log(list);
  const [postCertificado, setPostCertificado] = useState([]);
  const [postDocumento, setPostDocumento] = useState([]);
  const [postEmpresa, setPostEmpresa] = useState([]);

  const correoCookie = cookie.get("correo");
  const correoDecodificado = decodeURIComponent(correoCookie);
  const correoParametro = params.correo;
  const correoDecodificadoPrametro = decodeURIComponent(correoParametro);
  console.log(correoDecodificado);

  // Obtener el valor del parámetro "correo" (suponiendo que params sea un objeto que contiene los parámetros de la URL)

  // Comparar el valor de la cookie con el valor del parámetro
  if (correoDecodificado != correoDecodificadoPrametro) {
    // Si los valores no son iguales, redirigir a la URL específica
    window.location.href =
      "https://middlewarebackprd.azurewebsites.net/api/v1/AuthAzure";
  } else {
    // Si los valores son iguales, no hacer nada o realizar alguna acción adicional
  }
  const InicarSesion = (e) => {
    e.preventDefault();
    window.location.href = "/Login";
  };

  const postData = (e) => {
    e.preventDefault(e);
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerCertificado",
      data: {
        certificado: postCertificado + "",
        documento: postDocumento + "",
        Empresa: postEmpresa + "",
      },
    })
      .then((Response) => {
        if (Response.data == "") {
          window.alert(
            "La información ingresada no corresponde a un certificado valido"
          );
        } else {
          console.log(Response.data);
          setList(Response.data);
        }
      })
      .catch((error) => {
        window.alert("Ocurrio un error verifique los campos");
        console.log(error);
      });
  };

  const btnInforme = useCallback((row) => {
    window.open("/#/Informe/" + row.getValue("numero_certificado"), "_blank");
  });

  const btnInformeEnblanco = useCallback((row) => {
    window.open(
      "/#/InformeSinFondo/" + row.getValue("numero_certificado"),
      "_blank"
    );
  });

  const [list1, setList1] = useState([]);

  const urlCertificado = "https://public-api.acreditta.com/credential/record/";

  const btnPlantilla = useCallback((row) => {
    window.location.href =
      urlCertificado + row.getValue("id_certificado") + "?forPrinting=true";
  });
  const btnConsulta = (e) => {
    window.location.href = "/#/DetalleCertificado";
  };
  const Recarga = (e) => {
    window.location.reload("/");
  };
  var formData = new FormData();
  formData.append("title", "test");
  formData.append("slug", "test");

  const [tableData, setTableData] = useState(() => list);

  const btnInterno = useCallback(
    (row) => {
      axios({
        method: "post",
        url: "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraEducacion/Educacion01/Educacion",

        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        data: {
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            title: "Generado",
            icon: "info",
            html:
              "<p>Click aqui:<a href=" +
              response.data +
              " target='_blank'>Link de Descarga</a></p>",

            showCloseButton: true,

            focusConfirm: false,
            confirmButtonText: "Ok",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      // Aquí puedes realizar otras acciones después de la descarga.
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const btnEnblancoIcontec = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url: "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSG/IC0000001/BLANCO",
        data: {
          id_certificado: row.getValue("id_certificado"),
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const btnEnblancoIcontecSinFirma = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url: "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSG/IC0000002/SINFIRMA",
        data: {
          id_certificado: row.getValue("id_certificado"),
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const btnEnblancoIQNET = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url: "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSG/IQ000001/IQBLANCO",
        data: {
          id_certificado: row.getValue("id_certificado"),
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const btnRevoca = useCallback(
    (row) => {
      axios({
        method: "get",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/revocarCredencial/" +
          row.getValue("id_certificado"),
        data: {},
      })
        .then((Response) => {
          Swal.fire({
            title: Response.data,
            text: "Actualizado",
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const btnSendInterno = useCallback((row) => {
    const url =
      "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaIQ/" +
      row.getValue("numero_certificado");

    // Abre la URL en una nueva pestaña
    window.open(url, "_blank");
  });

  const btnSend = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSG/" +
          row.getValue("modulo") +
          "/ICONTEC",
        data: {
          id_certificado: row.getValue("id_certificado"),
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const btnMigrado = useCallback(
    (row) => {
      axios({
        method: "get",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/urlCertificado/" +
          row.getValue("modulo"),
        data: {
          estado: "Revocado",
        },
      })
        .then((Response) => {
          Swal.fire({
            title: Response.status,
            text: "Actualizado",
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const Acredita = useCallback((row) => {
    Swal.fire({
      title: "Generando credencial...",
      text: "Espere un momento mientras se genera la credencial.",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaCertificado",

      data: {
        modulo: row.getValue("modulo"),
        numero_certificado: row.getValue("numero_certificado"),
        correo: row.getValue("correo"),
        envio_correo: row.getValue("envio_correo"),
        documento: row.getValue("documento"),
        nombre: row.getValue("nombre"),
        asistio: row.getValue("asistio"),
        certificado: row.getValue("certificado"),
        certidicado_ingles: row.getValue("certificado_ingles"),
        alcance: row.getValue("alcance"),
        alcance_ingles: row.getValue("alcance_ingles"),
        intensidad: row.getValue("intensidad"),
        listado_cursos: row.getValue("listado_cursos"),
        regional: row.getValue("regional"),
        ciudad: row.getValue("ciudad"),
        pais: row.getValue("pais"),
        fecha_certificado: row.getValue("fecha_certificado"),
        fecha_aprobacion: row.getValue("fecha_aprobacion"),
        fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
        fecha_renovacion: row.getValue("fecha_renovacion"),
        fecha_vencimiento: row.getValue("fecha_vencimiento"),
        fecha_impresion: row.getValue("fecha_impresion"),
        direccion: row.getValue("direccion"),
        dato_1: row.getValue("dato_1"),
        dato_2: row.getValue("dato_2"),
        dato_3: row.getValue("dato_3"),
        dato_4: row.getValue("dato_4"),
        dato_5: row.getValue("dato_5"),
        dato_6: row.getValue("dato_6"),
        dato_7: row.getValue("dato_7"),
        dato_8: row.getValue("dato_8"),
        dato_9: row.getValue("dato_9"),
        dato_10: row.getValue("dato_10"),
        dato_11: row.getValue("dato_11"),
        dato_12: row.getValue("dato_12"),
        dato_13: row.getValue("dato_13"),
        dato_14: row.getValue("dato_14"),
        dato_15: row.getValue("dato_15"),
        estado: "Activo",
        emitido: "Acredita",
        dato_16: row.getValue("dato_16"),
        dato_17: row.getValue("dato_17"),
        dato_18: row.getValue("dato_18"),
        dato_19: row.getValue("dato_19"),
        dato_20: row.getValue("dato_20"),
      },
    })
      .then((Response) => {
        console.log(Response.data);
        const respuesta = Response.data;

        Swal.fire({
          title: "CREDENCIAL",
          text: "respuesta.resultado,",
          html:
            respuesta.resultado +
            "<p>Click aqui:<a href=" +
            respuesta.url +
            " target='_blank'>Link de Descarga</a></p>",

          icon: "success",
          type: "success",
          confirmButtonText: "Confirmar",
          showCancelButton: true,
        }).then(function () {
          axios({
            method: "delete",
            url:
              "https://middlewarebackprd.azurewebsites.net/api/v1/ElminaPendiente/" +
              row.getValue("id"),
          })
            .then((Response) => {
              console.log(Response.data);
              Swal.fire({
                title: Response.data,
                text: "Una vez emitido no podra regresar",
                icon: "success",
                type: "success",
                confirmButtonText: "Ok",
                showCancelButton: true,
              }).then(function () {
                window.location.reload("/");
              });
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })

      .catch((error) => {
        console.log(error);
        alert("Error al Actualizar" + error);
      });
    //send/receive api updates here
    setTableData([...tableData]);
    console.log("post data ");
  });

  const btnConsultaSG = useCallback((row) => {
    const url =
      "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaSG/" +
      row.getValue("numero_certificado");

    // Abre la URL en una nueva pestaña
    window.open(url, "_blank");
  });

  const btnHistorial = useCallback((row) => {
    window.location.href = "/#/Historial/" + row.getValue("numero_certificado");
  });

  const btnHistorialGeneral = useCallback((row) => {
    window.location.href =
      "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaHistorico/" +
      row.getValue("id_certificado");
  });

  const btnConsultaEducacion = useCallback(
    (row) => {
      axios({
        method: "get",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaEducacion/" +
          row.getValue("id_certificado"),
        data: {},
        responseType: "arraybuffer", // Set the response type to arraybuffer
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);

          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aquí: <a href='" +
              url +
              "' target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error!",
            text: "Verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const btnEspanol = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSGIdiomas/" +
          row.getValue("modulo") +
          "/IQ/es",
        data: {
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const btnIngles = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSGIdiomas/" +
          row.getValue("modulo") +
          "/IQ/en",
        data: {
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const btnEspanolIcontec = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSGIdiomas/" +
          row.getValue("modulo") +
          "/ICONTEC/es",
        data: {
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",

            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const btnInglesIcontec = useCallback(
    (row) => {
      axios({
        method: "post",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/GeneraSGIdiomas/" +
          row.getValue("modulo") +
          "/ICONTEC/en",
        data: {
          modulo: row.getValue("modulo"),
          numero_certificado: row.getValue("numero_certificado"),
          correo: row.getValue("correo"),
          documento: row.getValue("documento"),
          nombre: row.getValue("nombre"),
          certificado: row.getValue("certificado"),
          certidicado_ingles: row.getValue("certificado_ingles"),
          alcance: row.getValue("alcance"),
          alcance_ingles: row.getValue("alcance_ingles"),
          intensidad: row.getValue("intensidad"),
          listado_cursos: row.getValue("listado_cursos"),
          regional: row.getValue("regional"),
          ciudad: row.getValue("ciudad"),
          pais: row.getValue("pais"),
          fecha_certificado: row.getValue("fecha_certificado"),
          fecha_aprobacion: row.getValue("fecha_aprobacion"),
          fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
          fecha_renovacion: row.getValue("fecha_renovacion"),
          fecha_vencimiento: row.getValue("fecha_vencimiento"),
          fecha_impresion: row.getValue("fecha_impresion"),
          direccion: row.getValue("direccion"),
          dato_1: row.getValue("dato_1"),
          dato_2: row.getValue("dato_2"),
          dato_3: row.getValue("dato_3"),
          dato_4: row.getValue("dato_4"),
          dato_5: row.getValue("dato_5"),
          dato_6: row.getValue("dato_6"),
          dato_7: row.getValue("dato_7"),
          dato_8: row.getValue("dato_8"),
          dato_9: row.getValue("dato_9"),
          dato_10: row.getValue("dato_10"),
          dato_11: row.getValue("dato_11"),
          dato_12: row.getValue("dato_12"),
          dato_13: row.getValue("dato_13"),
          dato_14: row.getValue("dato_14"),
          dato_15: row.getValue("dato_15"),
          estado: row.getValue("estado"),
          emitido: row.getValue("emitido"),
          dato_16: row.getValue("dato_16"),
          dato_17: row.getValue("dato_17"),
          dato_18: row.getValue("dato_18"),
          dato_19: row.getValue("dato_19"),
          dato_20: row.getValue("dato_20"),
        },
      })
        .then((Response) => {
          Swal.fire({
            title: "Generado",
            text: "Actualizado",
            html:
              "<p>Click aqui:<a href=" +
              Response.data +
              " target='_blank'>Link de Descarga</a></p>",
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  return (
    <div>
      <link
        href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
        rel="stylesheet"
        id="bootstrap-css"
      />

      <div className="row">
        <div className="col-md-12">
          <center>
            <font color="#0085CA">
              <h1>Consulta el certificado </h1>
            </font>
          </center>
          {list == "" ? (
            <form method="post">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <font color="#002E5D">
                      {" "}
                      <strong> Certificado(*):</strong>
                    </font>
                    <input
                      type="text"
                      name="txtName"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => setPostCertificado(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <font color="#002E5D">
                      {" "}
                      <strong>Documento:</strong>
                    </font>
                    <input
                      type="text"
                      name="txtEmail"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => setPostDocumento(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <font color="#002E5D">
                      {" "}
                      <strong> Empresa o Nombre:</strong>{" "}
                    </font>
                    <input
                      type="text"
                      name="txtPhone"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => setPostEmpresa(e.target.value)}
                    />
                  </div>

                  <center>
                    <button
                      className="btn btn-primary btn-lg"
                      color="primary"
                      onClick={postData}
                    >
                      CONSULTAR
                    </button>
                  </center>

                  <hr />
                </div>
              </div>
            </form>
          ) : (
            <h></h>
          )}
          {list != "" ? (
            <div>
              <button
                className="btn btn-secondary btn-lg"
                color="primary"
                onClick={Recarga}
              >
                Nueva Consulta
              </button>
              <MaterialReactTable
                title="indicadores"
                localization={MRT_Localization_ES}
                enableStickyHeader
                columns={columns}
                data={list}
                enableRowActions
                editingMode="modal" //default
                enableStickyFooter
                initialState={{
                  columnVisibility: {
                    id_certificado: false,
                    showColumnFilters: true,
                  },
                }}
                renderRowActions={({ row, table }) =>
                  cookie.get("tipoUsuario") != "2" ? (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <div>
                        {row.getValue("dato_15") != "Campus" ? (
                          <div>
                            {cookie.get("tipoUsuario") != 6 ? (
                              <Tooltip
                                arrow
                                placement="right"
                                title="En Blanco Icontec Con Firma"
                              >
                                <IconButton
                                  onClick={() => btnEnblancoIcontec(row)}
                                >
                                  <FaFile color="#808080" />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <k></k>
                            )}
                            {cookie.get("tipoUsuario") != 6 ? (
                              // cookie.get("tipoUsuario") != 5
                              <Tooltip
                                arrowa
                                placement="right"
                                title="En Blanco Icontec Sin Firma"
                              >
                                <IconButton
                                  onClick={() =>
                                    btnEnblancoIcontecSinFirma(row)
                                  }
                                >
                                  <FaFile color="#808080" />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <l></l>
                            )}
                          </div>
                        ) : (
                          <h></h>
                        )}
                        {row.getValue("dato_15") != "Campus" &&
                        cookie.get("tipoUsuario") != 6 ? (
                          <Tooltip
                            arrow
                            placement="right"
                            title="En Blanco IQNET"
                          >
                            <IconButton onClick={() => btnEnblancoIQNET(row)}>
                              <FaFile color="#808080" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <h></h>
                        )}
                      </div>
                      {cookie.get("tipoUsuario") == 1 ? (
                        <Tooltip
                          arrow
                          placement="right"
                          title="Ver Historico General"
                        >
                          <IconButton
                            color="primary"
                            onClick={() => btnHistorialGeneral(row)}
                          >
                            <FaHistory />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <h></h>
                      )}
                      {cookie.get("tipoUsuario") != 6 ? (
                        <div>
                          {row.getValue("emitido") == "Acredita" ? (
                            <Tooltip
                              arrow
                              placement="right"
                              title="Ver Historico"
                            >
                              <IconButton
                                color="primary"
                                onClick={() => btnHistorial(row)}
                              >
                                <FaHistory />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <h></h>
                          )}
                        </div>
                      ) : (
                        <h></h>
                      )}
                      {row.getValue("emitido") == "Interno" &&
                      row.getValue("dato_15") == "Campus" ? (
                        <Tooltip
                          arrow
                          placement="right"
                          title="Ver Certificado"
                        >
                          <IconButton
                            color="success"
                            onClick={() => btnInterno(row)}
                          >
                            <FaFilePdf />
                          </IconButton>
                        </Tooltip>
                      ) : row.getValue("emitido") == "Acredita" ? (
                        <Tooltip
                          arrow
                          placement="right"
                          title="Ver Certificados"
                        >
                          <IconButton
                            color="primary"
                            onClick={() => btnConsultaSG(row)}
                          >
                            <FaFilePdf />
                          </IconButton>
                        </Tooltip>
                      ) : row.getValue("emitido") === "Interno" ? (
                        <div>
                          <Tooltip
                            arrow
                            placement="right"
                            title="Ver Certificado IQ"
                          >
                            <IconButton
                              color="default"
                              onClick={() => btnSendInterno(row)}
                            >
                              <FaFilePdf />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            arrow
                            placement="right"
                            title="Ver Certificado ICONTEC"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => btnConsultaSG(row)}
                            >
                              <FaFilePdf />
                            </IconButton>
                          </Tooltip>
                          {row.getValue("emitido") != "Acredita" &&
                          cookie.get("tipoUsuario") != 6 &&
                          cookie.get("tipoUsuario") != 5 ? (
                            <Tooltip
                              arrow
                              placement="right"
                              title=" Emitir Acredita"
                            >
                              <IconButton
                                color="error"
                                onClick={() => Acredita(row)}
                              >
                                <FaArrowAltCircleRight />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <h></h>
                          )}
                          {cookie.get("id") == 6 ? (
                            <Tooltip
                              arrow
                              placement="right"
                              title=" Emitir Acredita"
                            >
                              <IconButton
                                color="error"
                                onClick={() => Acredita(row)}
                              >
                                <FaArrowAltCircleRight />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <h></h>
                          )}
                        </div>
                      ) : row.getValue("emitido") == "Migrado" ? (
                        <div>
                          <Tooltip
                            arrow
                            placement="right"
                            title="Ver Certificado"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => btnConsultaSG(row)}
                            >
                              <FaFilePdf />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            arrow
                            placement="right"
                            title="Ver Certificado IQ"
                          >
                            <IconButton
                              color="default"
                              onClick={() => btnSendInterno(row)}
                            >
                              <FaFilePdf />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        row.getValue("dato_15") == "Send"
                      )}

                      <div>
                        {cookie.get("tipoUsuario") == 1 ? (
                          <Tooltip arrow placement="right" title="Revocar">
                            <IconButton
                              color="info"
                              onClick={() => btnRevoca(row)}
                            >
                              <Update />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <l></l>
                        )}
                        {row.getValue("dato_15") == "Send" ? (
                          <div>
                            {cookie.get("tipoUsuario") != 6 ? (
                              //  cookie.get("tipoUsuario") != 5
                              <Tooltip
                                arrow
                                placement="right"
                                title="Ver anexo En Blanco"
                              >
                                <IconButton
                                  color="secondary"
                                  onClick={() => btnInformeEnblanco(row)}
                                >
                                  <FaFilePdf color="#808080" />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <l></l>
                            )}
                            <Tooltip arrow placement="right" title="Ver anexo">
                              <IconButton
                                color="warning"
                                onClick={() => btnInforme(row)}
                              >
                                <FaFilePdf />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : (
                          <h></h>
                        )}
                      </div>

                      {row.getValue("dato_15") == "Campus" ? (
                        cookie.get("tipoUsuario") != 6 ? (
                          <div></div>
                        ) : (
                          /*  <Tooltip
                          arrow
                          placement="right"
                          title="Consulta Educacion"
                        >
                          <IconButton
                            color="success"
                            onClick={() => btnConsultaEducacion(row)}
                          >
                            <FaSearch />
                          </IconButton>
                        </Tooltip>*/

                          <h></h>
                        )
                      ) : (
                        <h></h>
                      )}
                      {row.getValue("emitido") == "Interno" &&
                      row.getValue("dato_15") === "Send" &&
                      cookie.get("tipoUsuario") != 6 ? (
                        <div>
                          <Tooltip
                            arrow
                            placement="right"
                            title="Genera Español IQ"
                          >
                            <IconButton
                              color="success"
                              onClick={() => btnEspanol(row)}
                            >
                              <FaLanguage />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            arrow
                            placement="right"
                            title="Genera Ingles IQ"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => btnIngles(row)}
                            >
                              <FaLanguage />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <h></h>
                      )}
                      {row.getValue("emitido") == "Interno" &&
                      row.getValue("dato_15") === "Send" &&
                      cookie.get("tipoUsuario") != 6 ? (
                        <div>
                          <Tooltip
                            arrow
                            placement="right"
                            title="Genera Español ICONTEC"
                          >
                            <IconButton
                              color="success"
                              onClick={() => btnEspanolIcontec(row)}
                            >
                              <FaLanguage />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            arrow
                            placement="right"
                            title="Genera Ingles ICONTEC"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => btnInglesIcontec(row)}
                            >
                              <FaLanguage />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <h></h>
                      )}
                    </Box>
                  ) : (
                    <h></h>
                  )
                }
                renderDetailPanel={({ row }) => (
                  <Box
                    sx={{
                      display: "grid",
                      margin: "auto",
                      gridTemplateColumns: "1fr 1fr",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      <b>Alcance:</b> {row.original.alcance}
                    </Typography>
                    <Typography>
                      <b>Certificado:</b> {row.original.certificado}
                    </Typography>
                  </Box>
                )}
              />
            </div>
          ) : (
            <h></h>
          )}
        </div>
      </div>
      {correoDecodificado}
      {correoDecodificadoPrametro}
    </div>
  );
}
export default ConsultarAutenticado;
